import { Box } from "@mui/material";
import ContentAutocomplete from "./ContentAutocomplete";
import ContentForm from "./ContentForm";
import { pick } from "lodash";
import { ContentFromFirestore, ContentID } from "models/ContentUnit";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PATH_DASHBOARD } from "../../../routes/paths";
import { LanguageSelectionDropDown, MULTI_LINGUAL_OPTION } from "../../LanguageSelectionDropDown";
import { ContentUnitsContext } from "../../../contexts/ContentUnitsContext";
import { SupportedLanguages } from "../../../types/invitation/code";
import { ContentCollection, deleteContentItem, } from "utils/firebase/contentUtils";
import { MultilingualLocalesTypes } from "../../../types/translations";
import MultiLingualAutocomplete from "./MultiLingualAutocomplete";
import useSettings from "../../../hooks/useSettings";

export default function ContentFormContainer() {
  const [selectedContentUnit, setSelectedContentUnit] = useState<ContentID>();
  const [selectedTargetLanguages, setSelectedTargetLanguages] = useState<MultilingualLocalesTypes[]>([]);

  const { contentUnits, setContentLanguage, contentLanguage } =
    useContext(ContentUnitsContext);

  const { localeList } = useSettings()

  const { language, contentId } = useParams();

  const navigate = useNavigate();

  const handleLanguageChange = (newLanguage: SupportedLanguages) => {
    setContentLanguage(newLanguage)
    navigate("/content/content-units/")
    if ((newLanguage as string) === MULTI_LINGUAL_OPTION) {
      setSelectedTargetLanguages(localeList)
    }
  }

  const onSelectMultilingualLanguage = (selectedLangs: MultilingualLocalesTypes[]) => {
    setSelectedTargetLanguages(selectedLangs)
  }

  useEffect(() => {
    if (contentId && contentId !== selectedContentUnit) {
      setSelectedContentUnit(contentId);
    }
  }, [contentId, selectedContentUnit]);

  useEffect(() => {
    if (language as string === MULTI_LINGUAL_OPTION && contentId) {
      const data = contentUnits?.[contentId]
      setSelectedTargetLanguages(data?.targetMultilingualLocales ?? [])
    }
  }, [language, contentId, contentUnits]);

  useEffect(() => {
    if (language && language !== contentLanguage) {
      // we know language param is set by the ContentAutocomplete to type SupportedLanguages
      setContentLanguage(language as SupportedLanguages);
    }
  }, [language, contentLanguage, setContentLanguage]);

  if (!contentUnits) {
    return null;
  }

  const contentUnitOptions = Object.values(contentUnits).map((contentUnit) => {
    return pick(contentUnit, ["id", "title", "type"]) as ContentFromFirestore;
  });

  const defaultValue = contentUnitOptions.find(
    (contentUnit) => contentUnit?.id === selectedContentUnit
  );

  const data = contentId ? contentUnits?.[contentId] : undefined;
  const contentIds = Object.keys(contentUnits);

  const deleteContent = async (id: string, selectedLanguage: SupportedLanguages): Promise<void> => {
    await deleteContentItem(ContentCollection.CONTENT, id, selectedLanguage, localeList);
    setSelectedContentUnit("")
  };

  return (
    <Box>
      <Box my={ 2 }>
        <LanguageSelectionDropDown
          onSelectionChanged={ (fieldKey: string, value: SupportedLanguages) => {
            handleLanguageChange(value);
          } }
          fieldName={ "selectedLanguage" }
          selectedValue={ contentLanguage }
          label={ "Select Language" }
          showMultiLingualOption
          localeList={ localeList }
        />
      </Box>

      <ContentAutocomplete
        setSelectedContentUnit={ setSelectedContentUnit }
        contentUnitOptions={ contentUnitOptions }
        defaultValue={ defaultValue }
        path={ PATH_DASHBOARD.contentUnit.root }
        textFieldLabel={ "Search for content unit by title or ID" }
        language={ contentLanguage }
      />

      <MultiLingualAutocomplete
        selectedTargetLanguages={ selectedTargetLanguages }
        onSelectMultilingualLanguage={ onSelectMultilingualLanguage }
        localeList={ localeList }
        selectedLanguage={ contentLanguage }/>

      <ContentForm
        data={ data }
        selectedMultilingualLanguages={selectedTargetLanguages}
        selectedLanguage={ contentLanguage }
        deleteContent={ deleteContent }
        contentUnitOptions={ contentIds }
      />
    </Box>
  );
}
